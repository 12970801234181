import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Text } from '@chakra-ui/react';
import ProductListing from './ProductListing';

const query = graphql`
  query ShopifyYamatoProducts {
    yamatoChoker: allShopifyCollection(
      filter: { id: { eq: "0e406095-9277-5c9f-9721-18a9dbf25646" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
        image {
          gatsbyImageData
        }
        description
        descriptionHtml
      }
    }
  }
`;

export default function ProductListingaYamato() {
  const queriedProducts =
    useStaticQuery<GatsbyTypes.ShopifyYamatoProductsQuery>(query);
  const products = queriedProducts.yamatoChoker.nodes[0]?.products;
  const image = queriedProducts.yamatoChoker.nodes[0]?.image;
  const description = queriedProducts.yamatoChoker.nodes[0]?.description;

  return (
    <>
      {image ? (
        <Box mb={4}>
          <GatsbyImage alt="" image={image.gatsbyImageData ?? null} />
        </Box>
      ) : null}
      <Text mb={8} fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
        {description}
      </Text>
      <ProductListing products={products} />
    </>
  );
}
