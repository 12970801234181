import * as React from 'react';
import {
  chakra,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  useBreakpointValue,
  Flex,
  Heading,
  Button,
  Text,
} from '@chakra-ui/react';

import CustomTab from './CustomTab';
import CartButton from './CartButton';
import ProductListingM7 from './ProductListingM7';
import ProductListingMajorChords from './ProductListingMajorChords';
import ProductListingSus4 from './ProductListingSus4';
import ProductListingDim from './ProductListingDim';
import ProductListingaYamato from './ProductListingYamato';
import ProductListingaWatchDeco from './ProductListingWatchDeco';
import { useRecoilState } from 'recoil';
import { filterState } from '../atoms/FilterAtom';
import { storeTabState } from '../atoms/StoreTabAtom';
import ProductListingaAllCollections from './ProductListingAllCollections';
import ProductVars from './ProductVars';

export default function StoreTabs() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [storeTab, setStoreTab] = useRecoilState(storeTabState);
  const [filter, setFilter] = useRecoilState(filterState);

  const setPriceFilter = (min: number, max: number) => {
    setFilter({
      ...filter,
      minPrice: min,
      maxPrice: max,
    });
  };

  const checkFocused = (min: number, max: number) => {
    return filter.minPrice === min && filter.maxPrice === max;
  };

  const setSelectPriceValue = (selectedValue: string) => {
    switch (selectedValue) {
      case 'option1':
        setPriceFilter(0, 1500);
        break;
      case 'option2':
        setPriceFilter(1500, 2000);
        break;
      case 'option3':
        setPriceFilter(2000, 3000);
        break;
      case 'option4':
        setPriceFilter(3000, 5000);
        break;
      case 'option5':
        setPriceFilter(5000, 999999999);
        break;
      default:
        setPriceFilter(0, 999999999);
        break;
    }
  };

  return (
    <Tabs
      isFitted
      onChange={index => setStoreTab(index)}
      bg="black"
      color="white"
      pt={isMobile ? 0 : 8}
      mb={40}
      index={storeTab}
    >
      {!isMobile ? null : <ProductVars setValue={setSelectPriceValue} />}
      {!isMobile ? (
        <Flex>
          <Flex w="30%" p={8} direction="column">
            <Box>
              <Heading fontSize="5xl">種別</Heading>
              <Box mt={4}>
                <CustomTab>全てのアイテム</CustomTab>
                <CustomTab>Major</CustomTab>
                <CustomTab>m7</CustomTab>
                <CustomTab>sus4</CustomTab>
                <CustomTab>dim</CustomTab>
                <CustomTab>Yamato</CustomTab>
              </Box>
            </Box>
            <Box mt={4} display="none">
              <Heading fontSize="5xl">価格帯</Heading>
              <Flex mt={4} gap={4} direction="column" alignItems="start">
                <Button
                  variant="unstyled"
                  onClick={() => setPriceFilter(0, 999999999)}
                >
                  <Text
                    textDecoration={
                      checkFocused(0, 999999999) ? 'underline' : 'none'
                    }
                    fontSize={{ base: 'xs', md: '4xl' }}
                  >
                    全て
                  </Text>
                </Button>
                <Button
                  variant="unstyled"
                  onClick={() => setPriceFilter(0, 1500)}
                >
                  <Text
                    textDecoration={
                      checkFocused(0, 1500) ? 'underline' : 'none'
                    }
                    fontSize={{ base: 'xs', md: '4xl' }}
                  >
                    〜1500円
                  </Text>
                </Button>
                <Button
                  variant="unstyled"
                  onClick={() => setPriceFilter(1500, 2000)}
                >
                  <Text
                    textDecoration={
                      checkFocused(1500, 2000) ? 'underline' : 'none'
                    }
                    fontSize={{ base: 'xs', md: '4xl' }}
                  >
                    1500円〜2000円
                  </Text>
                </Button>
                <Button
                  variant="unstyled"
                  onClick={() => setPriceFilter(2000, 3000)}
                >
                  <Text
                    textDecoration={
                      checkFocused(2000, 3000) ? 'underline' : 'none'
                    }
                    fontSize={{ base: 'xs', md: '4xl' }}
                  >
                    2000円〜3000円
                  </Text>
                </Button>
                <Button
                  variant="unstyled"
                  onClick={() => setPriceFilter(3000, 5000)}
                >
                  <Text
                    textDecoration={
                      checkFocused(3000, 5000) ? 'underline' : 'none'
                    }
                    fontSize={{ base: 'xs', md: '4xl' }}
                  >
                    3000円〜5000円
                  </Text>
                </Button>
                <Button
                  variant="unstyled"
                  onClick={() => setPriceFilter(5000, 999999999)}
                >
                  <Text
                    textDecoration={
                      checkFocused(5000, 999999999) ? 'underline' : 'none'
                    }
                    fontSize={{ base: 'xs', md: '4xl' }}
                  >
                    5000円〜
                  </Text>
                </Button>
              </Flex>
            </Box>
          </Flex>
          <TabPanels position="relative">
            <TabPanel>
              <ProductListingaAllCollections />
            </TabPanel>
            <TabPanel>
              <ProductListingMajorChords />
            </TabPanel>
            <TabPanel>
              <ProductListingM7 />
            </TabPanel>
            <TabPanel>
              <ProductListingSus4 />
            </TabPanel>
            <TabPanel>
              <ProductListingDim />
            </TabPanel>
            <TabPanel>
              <ProductListingaYamato />
            </TabPanel>
            <Box
              display={isMobile ? 'block' : 'none'}
              position="fixed"
              top="70vh"
              right="10vw"
              bg="purple.500"
              p={6}
              borderRadius="50%"
            >
              <CartButton />
            </Box>
          </TabPanels>
        </Flex>
      ) : (
        <TabPanels position="relative">
          <TabPanel>
            <ProductListingaAllCollections />
          </TabPanel>
          <TabPanel>
            <ProductListingMajorChords />
          </TabPanel>
          <TabPanel>
            <ProductListingM7 />
          </TabPanel>
          <TabPanel>
            <ProductListingSus4 />
          </TabPanel>
          <TabPanel>
            <ProductListingDim />
          </TabPanel>
          <TabPanel>
            <ProductListingaYamato />
          </TabPanel>
          <TabPanel>
            <ProductListingaWatchDeco />
          </TabPanel>
          <Box
            position="fixed"
            top="80vh"
            right="10vw"
            bg="purple.500"
            p={6}
            borderRadius="50%"
          >
            <CartButton />
          </Box>
        </TabPanels>
      )}
    </Tabs>
  );
}
