import { atom } from 'recoil';

import Filter from '../types/filter';

export const filterState = atom<Filter>({
  key: 'filter',
  default: {
    minPrice: 0,
    maxPrice: 999999999,
  },
});
