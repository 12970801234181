import * as React from 'react';
import { Tab } from '@chakra-ui/react';

const CustomTab = ({ children }: { children: React.ReactNode }) => (
  <Tab
    my={2}
    fontSize={{ base: 'xl', md: '4xl' }}
    _focus={{ boxShadow: 'none' }}
  >
    {children}
  </Tab>
);

export default CustomTab;
