import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { filterState } from '../atoms/FilterAtom';
import { ProductNode } from '../types/shopify';
import ProductPreviewCard from './ProductPreviewCard';

type ProductListingProps = {
  products: any;
  showBorderTop?: boolean;
};

function ProductListing({
  products,
  showBorderTop = true,
}: ProductListingProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [filter, _] = useRecoilState(filterState);

  const { minPrice, maxPrice } = filter;

  return (
    <Grid
      borderTop={showBorderTop ? '2px solid white' : 'none'}
      pt={8}
      templateColumns={isMobile ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'}
    >
      {products
        ?.filter((p: ProductNode) => p.images.length > 0)
        .filter(
          (p: ProductNode) =>
            Number(p.priceRangeV2.minVariantPrice.amount) < maxPrice &&
            Number(p.priceRangeV2.minVariantPrice.amount) > minPrice
        )
        .map((p: ProductNode) => (
          <GridItem w="100%" key={p?.shopifyId}>
            <ProductPreviewCard
              image={p?.images?.[0]}
              title={p?.title}
              price={p?.priceRangeV2?.minVariantPrice?.amount}
              currency={p?.priceRangeV2?.minVariantPrice?.currencyCode}
              handle={p?.handle}
            />
          </GridItem>
        ))}
    </Grid>
  );
}

export default ProductListing;
