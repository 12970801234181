import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { ProductNode } from '../types/shopify';
import StoreTabs from '../components/StoreTabs';
import { Box } from '@chakra-ui/react';
import { maxW } from '../assets/config';
import JsonLd from '../components/JsonLd';

type ProductsData = {
  data: {
    allProducts: {
      nodes: ProductNode[];
      pageInfo: {
        hasNextPage: false;
      };
    };
  };
};

export default function StorePage({ data: { allProducts } }: ProductsData) {
  return (
    <Layout bg="black">
      <Seo title="ストア" description="ChokerJokerの商品一覧ページです。" />
      <JsonLd title="ストア" description="ChokerJokerの商品一覧ページです。" />
      <Box maxW={maxW} mx="auto">
        <StoreTabs allProducts={allProducts.nodes} />
      </Box>
    </Layout>
  );
}

export const query = graphql`
  {
    allProducts: allShopifyProduct(sort: { fields: publishedAt, order: ASC }) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
