import * as React from 'react';
import { TabList, Select } from '@chakra-ui/react';

import CustomTab from './CustomTab';

type ProductVarsProps = {
  setValue: (value: HTMLSelectElement['value']) => void;
};

const ProductVars = ({ setValue }: ProductVarsProps) => {
  return (
    <>
      <TabList>
        <CustomTab>すべて</CustomTab>
        <CustomTab>Major</CustomTab>
        <CustomTab>m7</CustomTab>
        <CustomTab>sus4</CustomTab>
        <CustomTab>dim</CustomTab>
        <CustomTab>Yamato</CustomTab>
      </TabList>
      <Select
        display="none"
        mt={8}
        placeholder="価格帯を選択"
        onChange={e => setValue(e.target.value)}
      >
        <option value="option0">全て</option>
        <option value="option1">〜1500円</option>
        <option value="option2">1500円〜2000円</option>
        <option value="option3">2000円〜3000円</option>
        <option value="option4">3000円〜5000円</option>
        <option value="option5">5000円〜</option>
      </Select>
    </>
  );
};

export default ProductVars;
