import * as React from 'react';
import { Link } from 'gatsby';
import { BsCart } from 'react-icons/bs';
import { Box, Text, Icon } from '@chakra-ui/react';
import { StoreContext } from '../context/StoreContext';

export default function CartButton() {
  const { checkout } = React.useContext(StoreContext);

  const items = checkout ? checkout.lineItems : [];

  const quantity = items.reduce((total: number, item: { quantity: number }) => {
    return total + item.quantity;
  }, 0);

  return (
    <Box position="relative" zIndex="popover">
      <Link aria-label={`Shopping Cart with ${quantity} items`} to="/cart">
        <Icon as={BsCart} fontSize="4xl" color="white" />
      </Link>
      <Text
        as="span"
        fontSize={{ base: 'md', md: 'xl' }}
        fontWeight="bold"
        position="absolute"
        color="white"
        top={-4}
        right={-2}
      >
        {quantity > 0 && <div>{quantity}</div>}
      </Text>
    </Box>
  );
}
